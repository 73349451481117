html,
body {
  position: relative;
  height: 100%;
}

body {
  margin: 0;
  background: #eee;
  padding: 0;
  color: #000;
  font-size: 14px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-size: 18px;
  text-align: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.indexLunbo {
  position: relative;
  img {
    width: 100%;
  }
  video {
    width: 100%;
  }
  .home_001 {
    position: relative;
    .text_sm {
      position: absolute;
      // top: 21.02%;
      // left: 50%;
      // margin-left: -29.5%;
      // width: 59.66%;
      // height: 12.5%;
      top: 227px;
      left: 50%;
      transform: translateX(-50%);
      width: 1140px;
      height: 135px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .home_002 {
    position: relative;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .home2_text {
      position: absolute;
      top: 385px;
      right: 100px;
      .home2_text_1 {
        font-family: FZLTCHJW--GB1, FZLTCHJW--GB1;
        font-weight: normal;
        font-size: 110px;
        color: #ffffff;
        line-height: 110px;
        letter-spacing: 10px;
        white-space: nowrap;
      }
      .home2_text_2 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 30px;
        color: #ffffff;
        line-height: 48px;
        margin-top: 45px;
        white-space: nowrap;
      }
    }
  }
  .home_003 {
    position: relative;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .home3_text {
      position: absolute;
      top: 295px;
      left: 50%;
      transform: translateX(-50%);
      .home3_text_1 {
        font-family: FZLTCHJW--GB1, FZLTCHJW--GB1;
        font-weight: normal;
        font-size: 85px;
        color: #ffffff;
        line-height: 100px;
        letter-spacing: 3px;
        white-space: nowrap;
      }
      .home3_text_2 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 30px;
        color: #ffffff;
        line-height: 48px;
        white-space: nowrap;
        margin-top: 45px;
      }
    }
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 16.67%;
  }
}
.swiper-pagination-bullet-active {
  background: #fff !important;
}

.swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.7);
  width: 40px;
  height: 4px;
  border-radius: 0;
}
